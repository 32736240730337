import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BookingForm } from "../components/BookingForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useParams } from "react-router-dom";

import Slider from "react-slick";

import DOMPurify from "dompurify";
import { Form } from "react-bootstrap";
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}

const SightseeingDetails = () => {
  const [more, setMore] = useState(false);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const { room, loading, error } = useSelector((state) => state.roomDetails);

  // const htmlString = `${room?.map((i) => i.roomDetails)}`;
  // let hotelUrl = "";
  // if (window.location.href.includes("/agatha-christie-dahabiya")) {
  //   hotelUrl = "agatha-christie-dahabiya";
  // } else if (window.location.href.includes("/nile-capital-cruise")) {
  //   hotelUrl = "nile-capital-cruise";
  // }
  // useEffect(() => {
  //   if (roomURL) {
  //     dispatch(fetchRoomDetails({ hotelUrl, roomURL }));
  //   }
  // }, [dispatch, roomURL]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsDinings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const imgs = [
    { img: "assets/images/home-1/blog-details.jpg" },
    { img: "assets/images/home-1/blog-details.jpg" },
    { img: "assets/images/home-1/blog-details.jpg" },
  ];
  return (
    <>
      <Helmet>
        <title> Sightseeing Details </title>
      </Helmet>
      {/*banner */}
      <section>
        <div
          className="breadcumb-area d-flex align-items-center"
          style={{
            background: `url(assets/images/inner/breadcumb.jpg)`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "260px 0 170px",
            textAlign: "center",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="breacumb-content">
                  <Zoom duration="2500">
                    {" "}
                    <div className="breadcum-title">
                      <h4> Sightseeing_Name</h4>
                    </div>
                  </Zoom>
                  <Fade duration="2500" direction="up">
                    {" "}
                    <ul>
                      <li>
                        <Link to="/" style={{ color: "#fff" }}>
                          Home
                        </Link>
                      </li>
                      <li className="px-2"> / </li>
                      <li>
                        <Link to="/sightseeing" style={{ color: "#fff" }}>
                          Sightseeing
                        </Link>
                      </li>
                      <li className="px-2"> / </li>
                      <li>Sightseeing_Name</li>
                    </ul>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*booking form */}
      <BookingForm />

      {/*cabin Details */}
      <section id="cabin-details">
        <div className="room-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="room-details-list">
                    <Slider {...sliderSettings}>
                      {imgs.map((i) => (
                        <div className="col-lg-12">
                          <div className="room-detils-thumb">
                            <img src={i.img} alt="" />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="blog-details-content">
                      <h4 style={{ color: "#C29D68" }}>Sightseeing </h4>
                      <h1> Sightseeing_Name </h1>
                      <div
                        className="blog-details-desc"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                      <div
                        className="blog-details-desc"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                      <div
                        className="blog-details-title"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <h4>Rules &amp; Regulations </h4>
                      </div>
                      <div
                        className="blog-details-desc-2"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                      <div
                        className="blog-details-rules-item"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <ul>
                          <li>
                            <i className="fas fa-angle-double-right"></i>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            <i className="fas fa-angle-double-right"></i>
                            Rapidiously Lorem Ipsum is simply dummy text of the
                            printing and typesetting industry.
                          </li>
                          <li>
                            <i className="fas fa-angle-double-right"></i>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            <i className="fas fa-angle-double-right"></i>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="blog-details-thumb-2"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <img
                              src="assets/images/inner/blog-details-2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className="blog-details-thumb-2"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "750ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <img
                              src="assets/images/inner/blog-details-3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="blog-details-desc"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                      <div
                        className="blog-details-desc"
                        data-cue="zoomIn"
                        data-show="true"
                        style={{
                          animationName: "zoomIn",
                          animationDuration: "2500ms",
                          animationTimingFunction: "ease",
                          animationDelay: "0ms",
                          animationDirection: "normal",
                          animationFillMode: "both",
                        }}
                      >
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </p>
                      </div>
                      <div className="row akta-boder-add-korlam align-items-center">
                        <div className="col-md-6 col-sm-6">
                          <div
                            className="blog-details-tag"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <ul>
                              <li>Tags: </li>
                              <li>
                                <Link to="#.">SPA Center </Link>
                              </li>
                              <li>
                                <Link to="#.">Luxury </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div
                            className="blog-details-social"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <ul>
                              <li>Share: </li>
                              <li>
                                <Link to="#.">Fb </Link>
                              </li>
                              <li>
                                <Link to="#.">Tw </Link>
                              </li>
                              <li>
                                <Link to="#.">Ln </Link>
                              </li>
                              <li>
                                <Link to="#.">Pi </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div
                            className="blog-related-box"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <div className="blog-related-thumb">
                              <img
                                src="assets/images/inner/blog-related-1.jpg"
                                alt=""
                              />
                            </div>
                            <div className="blog-related-content">
                              <Link to="#.">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </Link>
                              <p>August 10, 2023 </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div
                            className="blog-related-box style-two"
                            data-cue="zoomIn"
                            data-show="true"
                            style={{
                              animationName: "zoomIn",
                              animationDuration: "2500ms",
                              animationTimingFunction: "ease",
                              animationDelay: "0ms",
                              animationDirection: "normal",
                              animationFillMode: "both",
                            }}
                          >
                            <div className="blog-related-content">
                              <Link to="#.">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </Link>
                              <p>August 10, 2023 </p>
                            </div>
                            <div className="blog-related-thumb">
                              <img
                                src="assets/images/inner/blog-related-2.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="blog-details-comment-title"
                            data-cue="zoomIn"
                          >
                            <h4>‘2’ Comments </h4>
                          </div>
                          <div
                            className="blog-details-comment"
                            data-cue="zoomIn"
                          >
                            <div className="blog-details-comment-reply">
                              <Link to="#.">Reply </Link>
                            </div>
                            <div className="blog-details-comment-thumb">
                              <img
                                src="assets/images/inner/blog-details-author-1.png"
                                alt=""
                              />
                            </div>
                            <div className="blog-details-comment-content">
                              <h2>Michael jordan </h2>
                              <span>22 August, 2023 </span>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </div>
                          </div>
                          <div
                            className="blog-details-comment style-two"
                            data-cue="zoomIn"
                          >
                            <div className="blog-details-comment-reply">
                              <Link to="#.">Reply </Link>
                            </div>
                            <div className="blog-details-comment-thumb">
                              <img
                                src="assets/images/inner/blog-details-author-2.png"
                                alt=""
                              />
                            </div>
                            <div className="blog-details-comment-content">
                              <h2>Johon Alex </h2>
                              <span>22 August, 2023 </span>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="blog-details-contact">
                        <div
                          className="blog-details-contact-title"
                          data-cue="zoomIn"
                        >
                          <h4>Leave A Comments </h4>
                        </div>
                        <form action="#">
                          <div className="row" data-cue="zoomIn">
                            <div className="col-lg-6">
                              <div className="contact-input-box">
                                <input
                                  type="text"
                                  name="Name"
                                  placeholder="Full Name*"
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="contact-input-box">
                                <input
                                  type="text"
                                  name="Email"
                                  placeholder="Email Address*"
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="contact-input-box">
                                <input
                                  type="text"
                                  name="Web Site"
                                  placeholder="Your Website*"
                                  required=""
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                              <div className="contact-input-box">
                                <textarea
                                  cols={12}
                                  name="Message"
                                  className="col-12 px-3"
                                  rows={5}
                                  placeholder="Write Comments..."
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="input-check-box">
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicCheckbox"
                                >
                                  <Form.Check.Input
                                    type="checkbox"
                                    isValid="false"
                                  />
                                  <Form.Check.Label>
                                    {" "}
                                    Save your email info the browser for next
                                  </Form.Check.Label>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="blog-details-submi-button">
                                <button type="submit" className="mt-2">
                                  Submit Now{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="widget_search">
                      <form action="#" method="get">
                        <input
                          type="text"
                          name="s"
                          value=""
                          placeholder="Search Here"
                          title="Search for:"
                        />
                        <button type="submit" className="icons">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div className="widget-categories-box">
                      <div className="categories_title" data-cue="zoomIn">
                        <h4>Popular Post</h4>
                      </div>
                      <div
                        className="sidber-widget-recent-post"
                        data-cue="zoomIn"
                      >
                        <div className="recent-widget-thumb">
                          <img
                            src="assets/images/inner/details-post-1.jpg"
                            alt=""
                          />
                        </div>
                        <div className="recent-widget-content">
                          <Link to="#.">
                            5 Discount Period every ____ for Valuable Clients
                          </Link>
                          <p> Jan, 26 2023 </p>
                        </div>
                      </div>
                      <div
                        className="sidber-widget-recent-post"
                        data-cue="zoomIn"
                      >
                        <div className="recent-widget-thumb">
                          <img
                            src="assets/images/inner/details-post-2.jpg"
                            alt=""
                          />
                        </div>
                        <div className="recent-widget-content">
                          <Link to="#.">
                            Pre Booking Benifits for ___ Traveller on our Hotel
                          </Link>
                          <p>Jan, 18 2023 </p>
                        </div>
                      </div>
                      <div
                        className="sidber-widget-recent-post style1"
                        data-cue="zoomIn"
                      >
                        <div className="recent-widget-thumb">
                          <img
                            src="assets/images/inner/details-post-3.jpg"
                            alt=""
                          />
                        </div>
                        <div className="recent-widget-content">
                          <Link to="#.">
                            How to Book a ____ online Step by Step _____
                          </Link>
                          <p> Jan, 15 2023 </p>
                        </div>
                      </div>
                    </div>
                    <div className="widget-categories-box" data-cue="zoomIn">
                      <div className="categories_title">
                        <h4>Categories</h4>
                      </div>
                      <div className="widget-catefories-list">
                        <ul>
                          <li>
                            <Link to="#.">
                              <i className="fas fa-angle-double-right"></i>
                              Luxury Hotels
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fas fa-angle-double-right"></i>
                              Restaurents
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fas fa-angle-double-right"></i>SPA
                              Center
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fas fa-angle-double-right"></i>
                              Health Club
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fas fa-angle-double-right"></i>
                              Industrial
                            </Link>
                          </li>
                          <li>
                            <Link href="#.">
                              <i className="fas fa-angle-double-right"></i>
                              Uncategories
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="widget-categories-box" data-cue="zoomIn">
                      <div className="categories_title">
                        <h4>Tags</h4>
                      </div>
                      <div className="widget-catefories-tags">
                        <Link to="#.">Luxury Hotel</Link>
                        <Link to="#.">Interior Design</Link>
                        <Link to="#.">SPA Center</Link>
                        <Link to="#.">Luxury Restaurent</Link>
                        <Link to="#.">Luxury Home</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SightseeingDetails;
