import React, { useEffect, useRef, useState } from "react";
import { isAfter, closestTo } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { fetchItineraries } from "../data/itinerariesThunks";
import { json, useNavigate } from "react-router-dom";
// import { fetchCabins } from "../data/bookingSlice";

import { fetchCruiseListBooking } from "../../data/groupBooking/cruiseListSlice";
import SearchIcon from "@mui/icons-material/Search";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { useMediaQuery } from "react-responsive";
import { Streetview } from "@mui/icons-material";
import { fetchBookingCruisesGroup } from "../../data/groupBooking/bookGroupSlice";
export const BookingFormGroup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AdultsList = [
    { adult: "1 Adult", val: 1 },
    { adult: "2 Adults", val: 2 },
  ];
  const ChildList = [
    { child: "0 Child", val: 0 },
    { child: "1 Child", val: 1 },
    { child: "2 Child", val: 2 },
  ];
  let cruiseUrl = null;
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    cruiseUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    cruiseUrl = "nile-capital-cruise";
  }

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [ItineraryTypeId, setItineraryTypeId] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [includeDates, setIncludeDates] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(true);
  const [adults, setAdults] = useState("");
  const [child, setChild] = useState("");
  const [availableChildrenOptions, setAvailableChildrenOptions] = useState([]);
  const [view, setView] = useState(false);

  const { cruisesListBooking, loadingCruisesList, errorCruisesList } =
    useSelector((state) => state.cruisesListBooking);
  const {
    bookingCruisesGroup,
    loadingbookingCruisesGroup,
    errorbookingCruisesGroup,
  } = useSelector((state) => state.bookingCruisesGroup);
  const cruisesList = bookingCruisesGroup?.map((itinerary) => ({
    id: itinerary.durationId,
    name: itinerary.itineraryNameSys,
  }));

  useEffect(() => {
    dispatch(fetchBookingCruisesGroup());
  }, [dispatch]);
  useEffect(() => {
    if (selectedOption) {
      const selectedItinerary = bookingCruisesGroup.find(
        (itinerary) => itinerary.durationId === selectedOption
      );
      setName(selectedItinerary.itineraryNameSys);
      const operationDates = selectedItinerary.list_OperationDates?.map(
        (date) => new Date(date.str_OperationDate)
      );
      setIncludeDates(operationDates);
    }
  }, [selectedOption, bookingCruisesGroup]);

  useEffect(() => {
    if (includeDates && includeDates.length > 0) {
      const today = new Date();

      const futureDates = includeDates.filter((date) => isAfter(date, today));

      const nearestFutureDate = closestTo(today, futureDates);

      setStartDate(nearestFutureDate);
      setStarted(false);
      setDisabled(false);
    }
  }, [includeDates]);

  useEffect(() => {
    if (adults === 2) {
      // If 2 adults are selected, only "0 Child" or "1 Child" should be available
      setAvailableChildrenOptions(ChildList.slice(0, 2));
    } else {
      // If 1 adult is selected, all options are available
      setAvailableChildrenOptions(ChildList);
    }
    // Reset child selection when adult selection changes
    setChild("");
  }, [adults]);
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleAdultsChange = (event) => {
    setAdults(event.target.value);
  };

  const handleChildChange = (event) => {
    setChild(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const year = startDate.getFullYear();
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2); // Add 1 because months are 0 indexed. Add leading zero and slice to ensure two digits.
    const day = ("0" + startDate.getDate()).slice(-2); // Add leading zero and slice to ensure two digits.

    const dateString = `${year},${month},${day}`;

    let formData = {
      LanguageCode: "en",
      durationId: selectedOption,
      Str_Date: dateString,
      AdultNumber: adults,
      ChildNumber: child,
    };

    localStorage.setItem("bookingFormData", JSON.stringify(formData));
    localStorage.setItem("itinerary_name", JSON.stringify(name));
    localStorage.setItem("itinearyIdGroup", selectedOption);
    let queryParams = new URLSearchParams(formData).toString();

    dispatch(fetchCruiseListBooking(formData));
    if (!errorbookingCruisesGroup) {
      return navigate(`/cruisesbooking?${queryParams}`);
    }
  };

  return (
    <div className="booking-area home-1" id="booking">
      <div
        className="row add-bg align-items-center  pt-3 pb-3"
        style={{
          display: isMobile ? "block" : "none",
          marginBottom: view && "95px",
        }}
      >
        <div
          className="btn btn-light d-flex align-items-center justify-content-center"
          style={{ padding: "0", transition: "all 0.2s ease-in-out" }}
          onClick={() => setView(!view)}
        >
          <SavedSearchIcon sx={{ color: "#D9B95E", fontSize: "2rem" }} />
          <span
            className="ps-2"
            style={{ color: "#D9B95E", transition: "all 0.2s ease-in-out" }}
          >
            {!view ? "Adjust Your Search" : ""}
          </span>
        </div>
      </div>
      <div className="container">
        <form
          onSubmit={handleSubmit}
          style={{ display: isMobile && !view ? "none" : "block" }}
        >
          <div className="row add-bg align-items-center">
            <div className="booking-input-box " style={{ width: "24%" }}>
              <p className="text-light">Where would like to go?</p>
              <FormControl
                required
                sx={{
                  m: 0,
                  minWidth: isMobile ? "92%" : 265,
                  maxWidth: isMobile ? "100%" : "92%",
                }}
              >
                <InputLabel id="demo-simple-select-required-label">
                  Select Itineraries
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={selectedOption}
                  label="Select Itineraries"
                  onChange={handleSelectChange}
                >
                  {cruisesList?.map((itinerary, idx) => (
                    <MenuItem key={idx} value={itinerary.id}>
                      {itinerary.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="booking-input-box " style={{ width: "24%" }}>
              <p className="text-light">Departs as early as</p>
              <div
                style={{
                  cursor: !selectedOption ? "none" : "pointer",
                }}
              >
                <DatePicker
                  style={{
                    opacity: !selectedOption ? "0" : "1",
                  }}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  value={selectedOption && includeDates[0]}
                  includeDates={includeDates}
                  minDate={new Date()}
                  selected={startDate}
                  disabled={!selectedOption && true}
                />
              </div>
              <span
                style={{
                  position: "absolute",
                  fontSize: "9px",

                  color: "#D9B95E",
                  textWrap: "nowrap",
                  opacity: 1,
                  zIndex: "999999",
                }}
              >
                {disabled && started
                  ? "Select Your Itinerary Type And View Available Dates"
                  : disabled
                  ? "Not Days Available , Select Another Type"
                  : ""}
              </span>
            </div>

            <div className="booking-input-box" style={{ width: "19%" }}>
              <p className="text-light">Adults (12+)</p>
              <FormControl
                required
                sx={{ m: 0, minWidth: isMobile ? "92%" : 200 }}
              >
                <InputLabel id="demo-simple-select-required-label-adults">
                  Select Adults
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label-adults"
                  id="demo-simple-select-required-adults"
                  value={adults}
                  label="Select Adults"
                  onChange={handleAdultsChange}
                >
                  {AdultsList?.map((adult, idx) => (
                    <MenuItem key={idx} value={adult.val}>
                      {adult.adult}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="booking-input-box" style={{ width: "19%" }}>
              <p className="text-light">Child (2-11.99)</p>
              <FormControl
                required
                sx={{ m: 0, minWidth: isMobile ? "92%" : 200 }}
                style={{
                  cursor: !adults ? "" : "pointer",
                }}
              >
                <InputLabel id="demo-simple-select-required-label-child">
                  Select Child
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label-child"
                  id="demo-simple-select-required-child"
                  value={child}
                  label="Select Child"
                  onChange={handleChildChange}
                  disabled={!adults}
                >
                  {availableChildrenOptions?.map((childOption, idx) => (
                    <MenuItem key={idx} value={childOption.val}>
                      {childOption.child}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!adults && (
                <span
                  style={{
                    position: "absolute",
                    fontSize: "9px",
                    left: "6%",
                    bottom: "-26px",
                    color: "#D9B95E",
                    textWrap: "nowrap",
                    opacity: 1,
                    zIndex: "999999",
                  }}
                >
                  select adults , then select children
                </span>
              )}
            </div>

            <div className="booking-button">
              <button type="submit">Book Now</button>
            </div>
          </div>
        </form>
        <div id="status"></div>
      </div>
    </div>
  );
};
