import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BookingForm } from "../../components/BookingForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { fetchRooms } from "../../data/roomsSlice";
import Loader from "../../Loader";
import { useMediaQuery } from "react-responsive";

const Cabins = () => {
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.rooms);
  useEffect(() => {
    dispatch(fetchRooms(hotelUrl));
  }, [dispatch]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title>{data?.pageMetatagTitle}</title>

                <meta name="keywords" content={data?.pageMetatagTitle} />
                <meta
                  name="description"
                  content={data.pageMetatagDescription}
                />
              </Helmet>
              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? data?.pageBannerMobile
                        : isTablet
                        ? data?.pageBannerTablet
                        : data?.pageBanner
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{data.pageTitle}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li> {data.pageTitle}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*booking form */}

              <BookingForm />
              {/*cabins list */}
              <section>
                <div className="room-area inner">
                  <div className="container">
                    <Zoom duration="2500" delay="0">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="section-title center inner">
                            <div className="section-thumb">
                              <img
                                src="section-shape1.png"
                                alt="section shape icon"
                                loading="lazy"
                              />
                            </div>
                            <h1>{data.pageTitle}</h1>
                            <p className="section-desc-1">{data.pageText}</p>
                          </div>
                        </div>
                      </div>
                    </Zoom>
                    <div className="row align-items-center">
                      {data.list_Rooms.map((room, index) => (
                        <Zoom
                          key={index}
                          className="col-lg-6 col-md-6"
                          duration="2500"
                          delay="0"
                          cascade
                        >
                          <div className="room-single-box">
                            <div className="room-thumb">
                              <img
                                src={room.roomPhoto}
                                alt={room.roomName}
                                loading="lazy"
                              />
                              <div className="room-details-button">
                                <Link
                                  to={`/${hotelUrl}/accommodation/${room.roomURL}`}
                                >
                                  View Details{" "}
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                            {/* <div className="room-pricing">
                              <span className="dolar">{room.price} </span>
                              <span>Night </span>
                            </div> */}
                            <div className="room-content">
                              <h4>{room.roomTypeCategoryName}</h4>
                              <Link
                                to={`/${hotelUrl}/accommodation/${room.roomURL}`}
                              >
                                {room.roomName}
                              </Link>

                              <div className="d-flex align-items-center">
                                <p>{room.roomSize}</p>
                                {room.maxOccupancy ? (
                                  <p className="px-3">|</p>
                                ) : (
                                  ""
                                )}

                                <p>{room.maxOccupancy}</p>
                              </div>
                            </div>
                            <div className="room-bottom">
                              <div className="room-bottom-icon">
                                <span>
                                  <img
                                    src="assets/images/home-1/room-bottom-icon.png"
                                    alt=""
                                    loading="lazy"
                                  />
                                  {room.roomBed}{" "}
                                </span>
                              </div>

                              <div className="coustomar-rating">
                                <ul>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                  <li>
                                    <i className="bi bi-star-fill"></i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Zoom>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Cabins;
