import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Layout from "./components/Layout";
import Group from "./pages/Group.jsx";
import ScrollToTop from "./ScrollToTop.jsx";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-image-lightbox/style.css";

import "./App.css";
import Cruises from "./pages/Cruises.jsx";
import Cabins from "./pages/cabins/Cabins.jsx";
import Dining from "./pages/dining/Dining.jsx";
import LeisureFacilities from "./pages/facilities/LeisureFacilities.jsx";
import Itineraries from "./pages/itineraries/Itineraries.jsx";
import ContactUs from "./pages/ContactUs";
import CabinsDetails from "./pages/cabins/CabinsDetails.jsx";
import LayoutCruise from "./components/LayoutCruise.jsx";
import LayoutDahabiya from "./components/LayoutDahabiya.jsx";
import DiningDetails from "./pages/dining/DiningDetails.jsx";
import FacilityDetails from "./pages/facilities/LeisureFacilitiesDetails.jsx";
import Faqs from "./pages/Faqs.jsx";
import { useEffect, useState } from "react";
import ItinerariesDetails from "./pages/itineraries/ItinerariesDetails.jsx";
import CruiseBooking from "./components/cruiseBooking/CruiseBooking.jsx";
import CabinBooking from "./components/cabinsBooking/CabinBooking.jsx";
import InsertCstData from "./components/cabinsBooking/InsertCstData.jsx";
import ThanksPage from "./components/cabinsBooking/ThanksPage.jsx";
import Sightseeing from "./pages/Sightseeing.jsx";
import SightseeingDetails from "./pages/SightseeingDetails.jsx";

import { useDispatch, useSelector } from "react-redux";
import { fetchItineraries } from "./data/itinerariesSlice.js";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import Gallery from "./pages/gallery/Gallery.jsx";

function App() {
  useEffect(() => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Check if the pathname includes '/en'
    if (!currentUrl.pathname.includes("/en")) {
      // Redirect by adding '/en' right after the domain
      window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
    }
  }, []);

  // const [cliced, setCliced] = useState(true);
  // const toggleLanguage = () => {
  //   let currentUrl = window.location.href;

  //   if (currentUrl.includes("/en")) {
  //     currentUrl = currentUrl.replace("/en", "/ar");
  //     setCliced(false);
  //   } else if (currentUrl.includes("/ar")) {
  //     currentUrl = currentUrl.replace("/ar", "/en");
  //     setCliced(true);
  //   }

  //   window.history.pushState({ path: currentUrl }, "", currentUrl);

  //   document.body.style.direction =
  //     document.body.style.direction === "rtl" ? "ltr" : "rtl";
  // };
  return (
    <>
      <Router basename="/en">
        {/*  <button
          className="my-auto fs-4"
          onClick={toggleLanguage}
          style={{
            position: "fixed",
            right: "33px",
            top: "35%",
            backgroundColor: "rgba(255,255,255,1)",
            border: "none",
            boxShadow: "0 0 2px #fff",
            width: "37px",
            height: "37px",
            borderRadius: "50%",
            zIndex: 999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cliced ? "Ar" : "En"}
        </button> */}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Group />} />
            <Route path="sightseeing" element={<Sightseeing />} />
            <Route
              path="sightseeing/:sightURL"
              element={<SightseeingDetails />}
            />
            <Route path="faq" element={<Faqs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cruisesbooking" element={<CruiseBooking />} />
            <Route path="cabinsbooking" element={<CabinBooking />} />
            <Route path="cabinsbooking/:cabinId" element={<InsertCstData />} />
            <Route path="thank-you" element={<ThanksPage />} />
          </Route>
          <Route path="/nile-capital-cruise" element={<LayoutCruise />}>
            <Route index element={<Cruises />} />

            <Route path="accommodation" element={<Cabins />} />
            <Route path="accommodation/:roomURL" element={<CabinsDetails />} />
            <Route path="dining" element={<Dining />} />
            <Route path="dining/:restaurantURL" element={<DiningDetails />} />
            <Route path="facilities" element={<LeisureFacilities />} />
            <Route
              path="facilities/:facilityURL"
              element={<FacilityDetails />}
            />
            <Route path="itineraries" element={<Itineraries />} />
            <Route
              path="itineraries/:itineraryURL"
              element={<ItinerariesDetails />}
            />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
          <Route path="/agatha-christie-dahabiya" element={<LayoutDahabiya />}>
            <Route index element={<Cruises />} />

            <Route path="accommodation" element={<Cabins />} />
            <Route path="accommodation/:roomURL" element={<CabinsDetails />} />
            <Route path="dining" element={<Dining />} />
            <Route path="dining/:restaurantURL" element={<DiningDetails />} />
            <Route path="facilities" element={<LeisureFacilities />} />
            <Route
              path="facilities/:facilityURL"
              element={<FacilityDetails />}
            />
            <Route path="itineraries" element={<Itineraries />} />
            <Route
              path="itineraries/:itineraryURL"
              element={<ItinerariesDetails />}
            />
            <Route path="gallery" element={<Gallery />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
