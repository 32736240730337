import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchContactInfo = createAsyncThunk(
  "contact/fetchContactInfo",
  async (hotelUrl) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/ContactPage/en/${hotelUrl}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch contact info");
    }
    return response.json();
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContactInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchContactInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contactSlice.reducer;
