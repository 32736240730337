import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchDiningDetails = createAsyncThunk(
  "diningDetails/fetchDiningDetails",
  async ({ hotelUrl, restaurantURL }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/GetRestaurantsDetails/en/${hotelUrl}/${restaurantURL}`
    );
    const data = await response.json();
    console.log(data);
    return data;
  }
);

const dining = createSlice({
  name: "diningDetails",
  initialState: { dining: null, loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiningDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDiningDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.dining = action.payload;
      })
      .addCase(fetchDiningDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dining.reducer;
