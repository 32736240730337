import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Fancybox from "../../components/fancybox-library/fancybox";
import styles from "./css/gallery.module.css";

import { Fade, Zoom } from "react-awesome-reveal";
import "./css/gallery.css";

import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { fetchGallery } from "../../data/gallerySlice";
import Loader from "../../Loader";
import { BookingForm } from "../../components/BookingForm";
import { useMediaQuery } from "react-responsive";
const Gallery = () => {
  const dispatch = useDispatch();
  const { gallery, loading, error } = useSelector((state) => state.gallery);
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  useEffect(() => {
    dispatch(fetchGallery(hotelUrl));
  }, [dispatch, hotelUrl]);

  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={gallery?.pageMetatagDescription}
                />
                <meta name="keywords" content={gallery?.pageMetatagTitle} />
                <title>{gallery?.pageTitle}</title>
              </Helmet>

              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? gallery?.pageBannerMobile
                        : isTablet
                        ? gallery?.pageBannerTablet
                        : gallery?.pageBanner
                    })`,
                  }}
                >
                  <div className="container" style={{ visibility: "hidden" }}>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{gallery?.pageTitle}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>{gallery?.pageTitle}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <BookingForm />
              <section className="first-section my-5 py-5">
                <Container>
                  <Zoom duration="2500" delay="0">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="section-title center inner">
                          <div className="section-thumb">
                            <img src="section-shape1.png" alt="" />
                          </div>
                          <h1>{gallery?.pageTitle}</h1>
                          <p className="section-desc-1"> {gallery?.pageText}</p>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                  <div
                    className={`${styles.gallery} `}
                    style={{ margin: "auto", textAlign: "center" }}
                  >
                    <Fancybox>
                      <div className="row">
                        <div className=" d-flex justify-content-between flex-wrap">
                          {gallery?.list_Photos.map((img, index) => (
                            <a
                              key={index}
                              data-fancybox="gallery"
                              href={img.photoFile}
                              className={`esg-center  eg-post-157 eg-playa-element-0  esg-transition  col-12 col-lg-3 mb-3  mb-lg-0 `}
                            >
                              <a
                                className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii  ${styles.egplayaelement0a} `}
                                href={img.photoFile}
                              >
                                +
                              </a>
                              <Zoom duration={2000} delay={0}>
                                {" "}
                                <img
                                  alt="gallery"
                                  src={img.photoFile}
                                  className="col-lg-12 col-12 p-0 p-lg-1"
                                />
                              </Zoom>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Fancybox>
                  </div>
                </Container>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Gallery;
