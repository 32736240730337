import React, { useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { isAfter, closestTo } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import RouteIcon from "@mui/icons-material/Route";
import { useNavigate } from "react-router-dom";
import { fetchCruiseListBooking } from "../../data/groupBooking/cruiseListSlice";
import Loader from "../../Loader";
import { Box, Hidden, useMediaQuery } from "@mui/material";
import { fetchBookingCruisesGroup } from "../../data/groupBooking/bookGroupSlice";
import { Accordion } from "react-bootstrap";
const CruiseBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AdultsList = [
    { adult: "1 Adult", val: 1 },
    { adult: "2 Adults", val: 2 },
  ];
  const ChildList = [
    { child: "0 Child", val: 0 },
    { child: "1 Child", val: 1 },
    { child: "2 Child", val: 2 },
  ];
  let cruiseUrl = null;
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    cruiseUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    cruiseUrl = "nile-capital-cruise";
  }

  const [ItineraryTypeId, setItineraryTypeId] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [includeDates, setIncludeDates] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [started, setStarted] = useState(true);
  const [adults, setAdults] = useState("");
  const [child, setChild] = useState("");
  const [availableChildrenOptions, setAvailableChildrenOptions] = useState([]);
  const [view, setView] = useState(false);
  const [itinearyIdGroup, setItinearyIdGroup] = useState("");
  const [changed, setChanged] = useState(false);
  const { cruisesListBooking, loadingCruisesList, errorCruisesList } =
    useSelector((state) => state.cruisesListBooking);
  const {
    bookingCruisesGroup,
    loadingbookingCruisesGroup,
    errorbookingCruisesGroup,
  } = useSelector((state) => state.bookingCruisesGroup);
  const cruisesList = bookingCruisesGroup?.map((itinerary) => ({
    id: itinerary.durationId,
    name: itinerary.itineraryNameSys,
  }));

  const storedData = JSON.parse(localStorage.getItem("bookingFormData"));
  const itinerary_name = JSON.parse(localStorage.getItem("itinerary_name"));
  localStorage.setItem(
    "itinearyIdGroup",
    cruisesListBooking.map((cruise) => cruise.itineraryId)
  );
  // useEffect(() => {
  //   dispatch(fetchCruiseListBooking(storedData));
  //   localStorage.setItem(
  //     "cruiseUrl",
  //     cruisesListBooking.map((cruise) => cruise.cruiseUrl)
  //   );
  // }, [dispatch]);
  useEffect(() => {
    dispatch(fetchBookingCruisesGroup());
  }, [dispatch]);
  useEffect(() => {
    if (selectedOption) {
      const selectedItinerary = bookingCruisesGroup.find(
        (itinerary) => itinerary.durationId === selectedOption
      );
      setName(selectedItinerary.itineraryNameSys);
      const operationDates = selectedItinerary.list_OperationDates?.map(
        (date) => new Date(date.str_OperationDate)
      );
      setIncludeDates(operationDates);
    }
  }, [selectedOption, bookingCruisesGroup]);

  useEffect(() => {
    if (includeDates && includeDates.length > 0) {
      const today = new Date();

      const futureDates = includeDates.filter((date) => isAfter(date, today));

      const nearestFutureDate = closestTo(today, futureDates);

      setStartDate(nearestFutureDate);
      setStarted(false);
      setDisabled(false);
    }
  }, [includeDates]);

  useEffect(() => {
    if (adults === 2) {
      // If 2 adults are selected, only "0 Child" or "1 Child" should be available
      setAvailableChildrenOptions(ChildList.slice(0, 2));
    } else {
      // If 1 adult is selected, all options are available
      setAvailableChildrenOptions(ChildList);
    }
    // Reset child selection when adult selection changes
    setChild("");
  }, [adults]);
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setChanged(true);
  };
  const handleAdultsChange = (event) => {
    setAdults(event.target.value);
  };

  const handleChildChange = (event) => {
    setChild(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const year = startDate.getFullYear();
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const day = ("0" + startDate.getDate()).slice(-2);

    const dateString = `${year},${month},${day}`;

    let formData = {
      LanguageCode: "en",
      durationId: selectedOption,
      Str_Date: dateString,
      AdultNumber: adults,
      ChildNumber: child,
    };

    localStorage.setItem("bookingFormData", JSON.stringify(formData));
    localStorage.setItem("itinerary_name", JSON.stringify(name));
    localStorage.setItem(
      "itinearyIdGroup",
      cruisesListBooking.map((cruise) => cruise.itineraryId)
    );

    let queryParams = new URLSearchParams(formData).toString();

    dispatch(fetchCruiseListBooking(formData));
    // if (!errorbookingCruisesGroup) {
    //   return navigate(`/cruisesbooking?${queryParams}`);
    // }
  };
  const [openFaqs, setOpenFaqs] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaqs((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  // trans string date to dates

  // Example usage:
  const updatedCruisesList = new Date(storedData.Str_Date);
  return (
    <>
      {errorCruisesList ? (
        errorCruisesList
      ) : (
        <>
          {loadingbookingCruisesGroup ? (
            <Loader />
          ) : (
            <>
              <>
                <Helmet>
                  <title>
                    {cruisesListBooking.map((cruise) => cruise.cruiseNameSys)}
                  </title>
                </Helmet>
                {/*banner */}

                <section>
                  <Box
                    className="breadcumb-area d-flex align-items-center"
                    style={{
                      background: `url(${cruisesListBooking.map(
                        (cruise) => cruise.cruiseBanner
                      )})`,
                    }}
                  >
                    <div className="container">
                      <div className="row align-items-center">
                        <div className="col-md-12">
                          <div className="breacumb-content">
                            <Zoom duration="2500">
                              {" "}
                              <div className="breadcum-title mt-5">
                                <h4>
                                  {cruisesListBooking.map(
                                    (cruise) => cruise.cruiseNameSys
                                  )}
                                </h4>
                              </div>
                            </Zoom>
                            <Fade
                              duration="2500"
                              direction="up"
                              style={{ visibility: "hidden" }}
                            >
                              {" "}
                              <ul>
                                <li>
                                  <Link to="/" style={{ color: "#fff" }}>
                                    Home
                                  </Link>
                                </li>
                                <li className="px-2"> / </li>
                                <li>
                                  {cruisesListBooking.map(
                                    (cruise) => cruise.cruiseNameSys
                                  )}
                                </li>
                              </ul>
                            </Fade>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </section>
                {/* form */}
                <section className="payment-area section-bg section-padding">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-box payment-received-wrap mb-0">
                          <div className="form-title-wrap">
                            <div className="step-bar-wrap text-center">
                              <ul className="step-bar-list d-flex align-items-center justify-content-around">
                                <li className="step-bar flex-grow-1 step-bar-active">
                                  <span className="icon-element">1 </span>
                                  <p className="pt-2 color-text-2">
                                    Choose Cruise{" "}
                                  </p>
                                </li>
                                <li className="step-bar flex-grow-1 ">
                                  <span className="icon-element">2 </span>
                                  <p className="pt-2 color-text-2">
                                    Choose Cabin{" "}
                                  </p>
                                </li>
                                <li className="step-bar flex-grow-1">
                                  <span className="icon-element">3 </span>
                                  <p className="pt-2 color-text-2">
                                    Booking Completed!{" "}
                                  </p>
                                </li>
                                <li className="step-bar flex-grow-1">
                                  <span className="icon-element">4 </span>
                                  <p className="pt-2 color-text-2">
                                    Booking Completed!{" "}
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="card-area section--padding my-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        {loadingCruisesList ? (
                          <Loader />
                        ) : (
                          <>
                            {cruisesListBooking.map((cruise) => (
                              <div
                                className="card-item flex-column cruise--card card-item-list"
                                key={cruise.cruiseId}
                              >
                                <div
                                  className="card-img"
                                  style={{ borderRadius: "0" }}
                                >
                                  <Link
                                    to="/cabinsbooking"
                                    onClick={() =>
                                      setItinearyIdGroup(cruise.itineraryId)
                                    }
                                    className="d-block"
                                  >
                                    <img
                                      src={cruise.cruisePhoto}
                                      alt="cruise-img"
                                      style={{
                                        height: "300px",
                                        borderRadius: "0",
                                      }}
                                    />
                                  </Link>
                                </div>
                                <div className="card-body  ">
                                  <p className="card-title text-main">
                                    <Link
                                      to="/cabinsbooking"
                                      onClick={() =>
                                        setItinearyIdGroup(cruise.itineraryId)
                                      }
                                    >
                                      <DirectionsBoatIcon
                                        sx={{ color: "#0A254C" }}
                                        className="me-2"
                                      />

                                      {cruise.cruiseNameSys}
                                    </Link>
                                  </p>
                                  <p
                                    className="card-meta "
                                    style={{ color: "#0A254C" }}
                                  >
                                    {/*  <RouteIcon
                                  sx={{ color: "#0A254C", fontSize: "14px" }}
                        />*/}
                                    {cruise.itineraryNameSys}
                                  </p>
                                  <div className="card-rating ">
                                    {cruise.cruiseDescription}
                                  </div>
                                  <div className="card-price d-flex flex-row align-items-baseline justify-content-between">
                                    <div
                                      className="card-btn"
                                      style={{
                                        position: "relative",
                                        right: "0%",
                                        bottom: "8%",
                                        marginTop: "25px",
                                      }}
                                    >
                                      <Link
                                        to="/cabinsbooking"
                                        id="Submit1"
                                        onClick={() =>
                                          setItinearyIdGroup(cruise.itineraryId)
                                        }
                                        className="theme-btn theme-btn-transparent px-5"
                                      >
                                        Select
                                      </Link>
                                    </div>{" "}
                                    <p
                                      style={{
                                        position: "relative",
                                        right: "0%",
                                        bottom: "0%",
                                        marginTop: "25px",
                                      }}
                                    >
                                      <span className="price__from">From</span>
                                      <span className="price__num">
                                        ${cruise.priceAdultBasic}
                                      </span>
                                    </p>
                                  </div>
                                  <hr
                                    className="mt-3 mb-0 bg-dark"
                                    style={{ borderBottom: "1px solid #ccc" }}
                                  />
                                  <Accordion defaultActiveKey={["0"]}>
                                    <Accordion.Item eventKey="1">
                                      <Accordion.Header className="p-0 border-0">
                                        <span to="#." className="fs-5 py-2">
                                          <i
                                            className="fa fa-info-circle  text-main"
                                            style={{ fontSize: "15px" }}
                                          ></i>{" "}
                                          View Itineraries
                                        </span>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="room-amenities-item">
                                          <ul className="col-12 d-flex justify-content-between flex-wrap">
                                            {cruise.itineraryDays?.map(
                                              (itinerary, index) => (
                                                <div
                                                  key={index}
                                                  className="faq-item col-12 pt-0 "
                                                  style={{
                                                    borderBottom:
                                                      "1px solid #D9DFE7",
                                                  }}
                                                >
                                                  <div
                                                    className={`question pt-3 pb-2 ${
                                                      openFaqs[index]
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      toggleFaq(index)
                                                    }
                                                  >
                                                    {itinerary.dayName}
                                                  </div>
                                                  {openFaqs[index] && (
                                                    <div
                                                      style={{
                                                        maxHeight: "1500px",
                                                        minHeight: "100px",
                                                      }}
                                                      className="  answer"
                                                    >
                                                      {itinerary.itineraryDayEvents.map(
                                                        (day, idx) => (
                                                          <p
                                                            key={idx}
                                                            className="fw-600 mb-0 py-0 ps-3 text-secondary"
                                                            dangerouslySetInnerHTML={{
                                                              __html: `${day.eventDescription}`,
                                                            }}
                                                          ></p>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <div className="col-lg-4 " id="form-booking-page">
                        <div className=" mt-0 ps-lg-5 pe-lg-3">
                          <form onSubmit={handleSubmit}>
                            <div className="row flex-column add-bg align-items-center d-block position-relative mt-0">
                              <p className="text-light">
                                Where would like to go?
                              </p>
                              <FormControl
                                required
                                sx={{ mb: 2, minWidth: 265, maxWidth: "100%" }}
                              >
                                <InputLabel id="demo-simple-select-required-label">
                                  {itinerary_name}
                                </InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-required-label"
                                  id="demo-simple-select-required"
                                  value={
                                    selectedOption
                                      ? selectedOption
                                      : itinearyIdGroup
                                  }
                                  label={itinerary_name}
                                  onChange={handleSelectChange}
                                >
                                  {cruisesList?.map((itinerary, idx) => (
                                    <MenuItem key={idx} value={itinerary.id}>
                                      {itinerary.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <p className="text-light">Departs as early as</p>
                              <FormControl
                                required
                                sx={{ mb: 2, minWidth: 265, maxWidth: "100%" }}
                              >
                                <InputLabel id="demo-simple-select-required-label2">
                                  Dates
                                </InputLabel>
                                <DatePicker
                                  required
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                  value={
                                    selectedOption && changed
                                      ? includeDates[0]
                                      : updatedCruisesList
                                  }
                                  includeDates={includeDates}
                                  minDate={new Date()}
                                  selected={
                                    startDate ? startDate : updatedCruisesList
                                  }
                                />
                              </FormControl>

                              <div className=" " style={{ width: "100%" }}>
                                <p className="text-light">Adults (12+)</p>
                                <FormControl
                                  sx={{ mb: 2, minWidth: "100%" }}
                                  required
                                >
                                  <InputLabel id="demo-simple-select-required-label-adults">
                                    {!changed
                                      ? cruisesListBooking.map(
                                          (cruise) =>
                                            cruise.adultNumber + " " + "Adult"
                                        )
                                      : "Select Adults"}
                                  </InputLabel>
                                  <Select
                                    required
                                    labelId="demo-simple-select-required-label-adults"
                                    id="demo-simple-select-required-adults"
                                    value={adults && adults}
                                    label={
                                      !changed
                                        ? cruisesListBooking.map(
                                            (cruise) => cruise.adultNumber
                                          )
                                        : "Select Adults"
                                    }
                                    onChange={handleAdultsChange}
                                  >
                                    {AdultsList.map((adult, idx) => (
                                      <MenuItem key={idx} value={adult.val}>
                                        {adult.adult}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                              <div
                                className="booking-input-box border-0 mb-4"
                                style={{ width: "100%" }}
                              >
                                <p className="text-light">Child (2-11.99)</p>
                                <FormControl
                                  required
                                  sx={{ m: 0, minWidth: "100%" }}
                                  style={{
                                    cursor: !adults ? "" : "pointer",
                                  }}
                                >
                                  <InputLabel id="demo-simple-select-required-label-child">
                                    {!changed
                                      ? cruisesListBooking.map(
                                          (cruise) =>
                                            cruise.childNumber + " " + "Child"
                                        )
                                      : "Select Child"}
                                  </InputLabel>
                                  <Select
                                    required
                                    labelId="demo-simple-select-required-label-child"
                                    id="demo-simple-select-required-child"
                                    value={child && child}
                                    label={
                                      !changed
                                        ? cruisesListBooking.map(
                                            (cruise) =>
                                              cruise.childNumber + " " + "Child"
                                          )
                                        : "Select Child"
                                    }
                                    onChange={handleChildChange}
                                    disabled={!adults && changed && true}
                                  >
                                    {availableChildrenOptions?.map(
                                      (childOption) => (
                                        <MenuItem
                                          key={childOption.val}
                                          value={childOption.val}
                                        >
                                          {childOption.child}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>

                                {!adults && changed && (
                                  <span
                                    style={{
                                      position: "absolute",
                                      fontSize: "9px",
                                      left: "6%",
                                      bottom: "-26px",
                                      color: "#D9B95E",
                                      textWrap: "nowrap",
                                      opacity: 1,
                                      zIndex: "999999",
                                    }}
                                  >
                                    select adults , then select children
                                  </span>
                                )}
                              </div>

                              <div className="col-lg-12 col-sm-12 pr-0">
                                <div className="btn-box pt-4  ">
                                  <input
                                    type="submit"
                                    className="theme-btn"
                                    value="Search Now"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CruiseBooking;
