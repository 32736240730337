import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchItinerariesPage = createAsyncThunk(
  "itinerariesPage/fetchItinerariesPage",
  async (hotelUrl) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/ItinerariesPage/en/${hotelUrl}`
    );
    const itinerariesPageData = await response.json();
    return itinerariesPageData;
  }
);

const itinerariesPageSlice = createSlice({
  name: "itinerariesPage",
  initialState: {
    data: [],
    loading: true, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItinerariesPage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchItinerariesPage.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.data = action.payload;
      })
      .addCase(fetchItinerariesPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default itinerariesPageSlice.reducer;
