import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchRoomDetails = createAsyncThunk(
  "roomDetails/fetchRoomDetails",
  async ({ hotelUrl, roomURL }) => {
    const response = await fetch(
      `https://apiws.nilecapitalcruises.com/api/GetRoomDetails/en/${hotelUrl}/${roomURL}`
      //`https://apiws.nilecapitalcruises.com/api/GetRoomDetails/en/royal-suite`
    );
    const data = await response.json();
    return data;
  }
);

const room = createSlice({
  name: "roomDetails",
  initialState: { room: null, loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchRoomDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.room = action.payload;
      })
      .addCase(fetchRoomDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default room.reducer;
