import React, { Fragment, useEffect, useState } from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import Loader from "../Loader";
import { Zoom } from "react-awesome-reveal";
import HomeIcon from "@mui/icons-material/Home";
import { Alert, Snackbar } from "@mui/material";
import { subscribeNewsletter } from "../data/newsLetterSlice";
import { useDispatch, useSelector } from "react-redux";

const LayoutCruises = () => {
  const currentYear = new Date().getFullYear();
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const checkScroll = () => {
      // Convert 70vh to pixels
      const triggerHeight = window.innerHeight * 0.2;
      const triggerHeight2 = window.innerHeight * 0.1;

      if (window.scrollY > triggerHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.scrollY > triggerHeight2) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", checkScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle the scroll to top action
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to check scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, [loading]);

  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu open and closed
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const { loadingNewsletter, errorNewsletter } = useSelector(
    (state) => state.newsLetter
  );

  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
    dispatch(subscribeNewsletter(email));
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            <div
              className={`header-area ${scrolled ? "sticky" : ""} `}
              id="sticky-header"
            >
              <div className="container-fulid">
                <div className="row align-items-center">
                  <div className="col-lg-2">
                    <div className="header-logo">
                      <Link to="/" onClick={() => setLoading(true)}>
                        <img src="logo-white.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="header-menu">
                      <ul>
                        <li>
                          <NavLink
                            to="/agatha-christie-dahabiya"
                            onClick={() => setLoading(true)}
                          >
                            Overview
                          </NavLink>
                        </li>
                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/accommodation"
                            onClick={() => setLoading(true)}
                          >
                            Accommodation{" "}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/dining"
                            onClick={() => setLoading(true)}
                          >
                            Dining{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/facilities"
                            onClick={() => setLoading(true)}
                          >
                            Leisure{" "}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/itineraries"
                            onClick={() => setLoading(true)}
                          >
                            Itineraries{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/gallery"
                            onClick={() => setLoading(true)}
                          >
                            Gallery{" "}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/agatha-christie-dahabiya/contact-us"
                            onClick={() => setLoading(true)}
                          >
                            Contact Us{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="header-button text-right">
                      <Link
                        to="/"
                        className="d-flex align-items-center justify-content-center"
                      >
                        <HomeIcon /> <span className="ms-2">Home</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="mobile-menu-area  d-sm-block d-md-block d-lg-none"
              style={{ position: "fixed", zIndex: "9999" }}
            >
              <div
                className={`mobile-menu mean-container ${isOpen ? "open" : ""}`}
              >
                <div className="mean-bar">
                  <Link to="/" onClick={() => setLoading(true)}>
                    <img
                      src="logo-white.png"
                      alt=""
                      className="ms-2 mt-1"
                      width="65"
                      height="50"
                    />
                  </Link>
                  <Link
                    to="#."
                    className="meanmenu-reveal meanclose"
                    onClick={toggleMenu}
                    style={{
                      right: 0,
                      left: "auto",
                      textAlign: "center",
                      textIndent: 0,
                      fontSize: 18,
                    }}
                  >
                    {isOpen ? (
                      "X"
                    ) : (
                      <>
                        <a
                          href="#nav"
                          className="meanmenu-reveal"
                          style={{ display: "contents" }}
                          onClick={() => toggleMenu()}
                        >
                          <span></span> <span></span> <span></span>
                        </a>
                      </>
                    )}
                  </Link>
                  <nav className="mean-nav">
                    <ul
                      className="nav_scroll"
                      style={{
                        maxHeight: !isOpen ? "0px" : "100vh",
                        zIndex: "99999999",
                        position: "relative",
                        overflow: "hidden",
                        transition: "max-height 0.5s linear",
                      }}
                    >
                      <li>
                        <Link
                          to="/agatha-christie-dahabiya"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Overview
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/agatha-christie-dahabiya/accommodation"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Accommodation
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/agatha-christie-dahabiya/dining"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Dining
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/agatha-christie-dahabiya/facilities"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Leisure
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/agatha-christie-dahabiya/itineraries"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Itineraries
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/agatha-christie-dahabiya/gallery"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Gallery{" "}
                        </Link>
                      </li>
                      <li className="mean-last">
                        <Link
                          to="/agatha-christie-dahabiya/contact-us"
                          onClick={() => {
                            setLoading(true);
                            toggleMenu();
                          }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="mean-push"></div>
              </div>
            </div>
          </header>

          <Outlet />

          <footer>
            <Zoom duration="2000" delay="0">
              <div className="footer-area home-five">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="footer-logo">
                        <Link to="#.">
                          <img src="logo-footer.png" alt="" />
                        </Link>
                      </div>
                      <div className="footer-widget-desc">
                        <p>
                          Indulge in an exquisite and enchanting journey aboard
                          the Nile Capital Cruises in full luxury and style as
                          you sail along the timeless and awe-inspiring Nile
                          River between the magnificent cities of Luxor and
                          Aswan.
                        </p>
                      </div>
                      <div className="footer-widget-social-icon">
                        {/* <ul>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-facebook-f"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-instagram"></i>
                            </Link>
                          </li>
                           <li>
                            <Link to="#.">
                              <i className="fab fa-twitter"></i>
                            </Link>
                          </li>
                         
                          <li>
                            <Link to="#.">
                              <i className="fab fa-youtube"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="#.">
                              <i className="fab fa-linkedin-in"></i>
                            </Link>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="footer-widget-content">
                        <div className="footer-widget-menu">
                          <div className="footer-widget-title">
                            <h4>About Us </h4>
                          </div>
                          <ul>
                            <li>
                              <Link to="/agatha-christie-dahabiya">
                                Overview
                              </Link>
                            </li>
                            <li>
                              <Link to="/agatha-christie-dahabiya/accommodation">
                                Accommodation{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/agatha-christie-dahabiya/dining">
                                Dining{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/agatha-christie-dahabiya/facilities">
                                {" "}
                                Leisure Facilities{" "}
                              </Link>
                            </li>

                            <li>
                              <Link to="/agatha-christie-dahabiya/itineraries">
                                Itineraries{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/agatha-christie-dahabiya/gallery">
                                Gallery{" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="footer-widget-content">
                        <div className="footer-widget-title">
                          <h4>General </h4>
                        </div>
                        <div className="footer-widget-menu">
                          <ul>
                            <li>
                              <Link to="/">Home </Link>
                            </li>

                            <li>
                              <Link to="/sightseeing">Sightseeing </Link>
                            </li>
                            <li>
                              <li>
                                <Link to="/agatha-christie-dahabiya/contact-us">
                                  Contact Us{" "}
                                </Link>
                              </li>
                              <Link to="/terms-conditions">
                                Terms & Conditions{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/privacy-policy">Privacy Policy </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="footer-widget-content">
                        <div className="footer-widget-title">
                          <h4>Newsletter</h4>
                        </div>
                        <p style={{ color: "#fff" }}>
                          Subscribe our Newsletter
                        </p>
                        <form onSubmit={handleSubmit}>
                          <div className="single-newsletter-box">
                            <input
                              type="email"
                              name="Email"
                              placeholder="Enter E-Mail"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <button type="submit">Subscribe Now</button>
                          </div>
                        </form>
                        {!loadingNewsletter && !errorNewsletter ? (
                          <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                          >
                            <Alert
                              onClose={handleClose}
                              severity="success"
                              variant="filled"
                              sx={{ width: "100%" }}
                            >
                              Thank you for subscribing!
                            </Alert>
                          </Snackbar>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row footer-bottom align-items-center">
                    <div className="col-lg-6 col-md-6">
                      <div className="footer-bottom-content">
                        <p>© 2024 All Rights Reserved Nile Capital Cruises </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="footer-bottom-content">
                        <div className="footer-bottom-item">
                          <ul>
                            <li>
                              <Link to="https://titegypt.com" target="_blank">
                                Powered by T.I.T. Solutions
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
          </footer>
        </>
      )}
    </>
  );
};

export default LayoutCruises;
