import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BookingForm } from "../../components/BookingForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchRoomDetails } from "../../data/roomDetailsSlice.js";
import Slider from "react-slick";
import Loader from "../../Loader.jsx";
import DOMPurify from "dompurify";
import { useMediaQuery } from "react-responsive";
function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-left"></i>
    </div>
  );
}

// Custom Arrow for the Next Button
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", fontSize: "20px" }}
      onClick={onClick}
    >
      <i className="bi bi-arrow-right"></i>
    </div>
  );
}

const CabinDetails = () => {
  const [more, setMore] = useState(false);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { roomURL } = useParams();
  const dispatch = useDispatch();
  const { room, loading, error } = useSelector((state) => state.roomDetails);

  const htmlString = `${room?.map((i) => i.roomDetails)}`;
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  useEffect(() => {
    if (roomURL) {
      dispatch(fetchRoomDetails({ hotelUrl, roomURL }));
    }
  }, [dispatch, roomURL]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settingsDinings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <title>{room?.map((i) => i.roomMetatagTitle)}</title>

                <meta
                  name="keywords"
                  content={room?.map((i) => i.roomMetatagTitle)}
                />
                <meta
                  name="description"
                  content={room?.map((i) => i.roomMetatagDescription)}
                />
              </Helmet>
              {/*banner */}
              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? room?.map((i) => i.roomBanner_Mobile)
                        : isTablet
                        ? room?.map((i) => i.roomBanner_Tablet)
                        : room?.map((i) => i.roomBanner)
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{room?.map((i) => i.roomName)}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>
                                <Link
                                  to={`/${hotelUrl}/accommodation`}
                                  style={{ color: "#fff" }}
                                >
                                  ROOMS & SUITES
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li>{room?.map((i) => i.roomName)}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/*booking form */}
              <BookingForm />

              {/*cabin Details */}
              <section id="cabin-details">
                <div className="room-details">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="room-details-list">
                            <Slider {...sliderSettings}>
                              {room?.map((i) =>
                                i.roomPhotos.map((x) => (
                                  <div className="col-lg-12">
                                    <div className="room-detils-thumb">
                                      <img src={x.photoFile} alt="" />
                                    </div>
                                  </div>
                                ))
                              )}
                            </Slider>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="room-details-content">
                              <h4>{room?.map((i) => i.roomTypeCategory)} </h4>
                              <h1>{room?.map((i) => i.roomName)}</h1>

                              <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{ __html: htmlString }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="row">
                          <div className="col-lg-12">
                            {/*  <div className="booking-list">
                              <div className="booking-list-content">
                                <h4>Booking </h4>
                              </div>
                              <div className="booking-item">
                                <ul>
                                  <li>
                                    <strong>Check In - </strong>04 Oct, 2023{" "}
                                  </li>
                                  <li>
                                    <strong>Check Out - </strong>10 Oct, 2023{" "}
                                  </li>
                                  <li>
                                    <strong>Adult - </strong>02{" "}
                                  </li>
                                  <li>
                                    <strong>Childreen - </strong>02{" "}
                                  </li>
                                </ul>
                              </div>
                            </div> */}
                            <div
                              className="room-details-amenities"
                              id="amenities"
                            >
                              <div className="room-details-amenities-content">
                                <h4>Amenities </h4>
                              </div>
                              <div className="room-amenities-item">
                                <ul
                                  style={{
                                    maxHeight: !more && "350px",
                                    overflow: !more && "hidden",
                                  }}
                                >
                                  {room?.map((i) =>
                                    i.roomAmenities.map((a) => (
                                      <li>
                                        <img
                                          title={a.roomAmenitiesName + " icon"}
                                          style={{ width: "25px" }}
                                          className="mr-1"
                                          src={a.roomAmenitiesPhoto}
                                          alt=" "
                                        />
                                        {a.roomAmenitiesName}
                                      </li>
                                    ))
                                  )}
                                </ul>

                                <Link
                                  className="view-more mt-3 d-block text-center mx-auto"
                                  onClick={() => {
                                    setMore(!more);
                                    more && scrollToSection("amenities");
                                  }}
                                >
                                  {!more ? "View More Amenities" : "View Less"}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*Dining*/}
              <section id="cabins">
                <div className="room-title-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <Zoom duration="3000" delay="0">
                          <div className="section-title center">
                            <div className="section-thumb">
                              <img
                                src="assets/images/home-1/section-shape1.png"
                                alt=""
                              />
                            </div>
                            <h1>Other Accommodation </h1>
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="room-area">
                  <div className="container">
                    <Zoom duration="3000" delay="0">
                      <div className="row margin-top">
                        <Slider {...settingsDinings}>
                          {room?.map((i) =>
                            i.roomOthers.map((cabin, index) => (
                              <div key={index} className="col-lg-12">
                                <div className="room-single-box">
                                  <div className="room-thumb">
                                    <img
                                      src={cabin.roomPhoto}
                                      alt={cabin.roomName}
                                    />
                                    <div className="room-details-button">
                                      <Link
                                        to={`/${hotelUrl}/accommodation/${cabin.roomURL}`}
                                      >
                                        View Details{" "}
                                        <i className="bi bi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <div className="room-pricing">
                                 <span className="dolar">$560 </span>
                                 <span>Night </span>
                               </div>*/}
                                  <div className="room-content">
                                    <h4>{cabin.roomTypeCategoryName}</h4>
                                    <Link
                                      to={`/${hotelUrl}/accommodation/${cabin.roomURL}`}
                                    >
                                      {cabin.roomName}
                                    </Link>
                                    <div className="d-flex align-items-center">
                                      <p>{cabin.roomSize}</p>
                                      {cabin.maxOccupancy ? (
                                        <p className="px-3">|</p>
                                      ) : (
                                        ""
                                      )}

                                      <p>{cabin.maxOccupancy}</p>
                                    </div>
                                  </div>
                                  <div className="room-bottom">
                                    <div className="room-bottom-icon">
                                      <span>
                                        <img
                                          src="assets/images/home-1/room-bottom-icon.png"
                                          alt=""
                                        />
                                        {cabin.roomBed}
                                      </span>
                                    </div>
                                    <div className="coustomar-rating">
                                      <ul>
                                        <li>
                                          <i className="bi bi-star-fill"></i>
                                        </li>
                                        <li>
                                          <i className="bi bi-star-fill"></i>
                                        </li>
                                        <li>
                                          <i className="bi bi-star-fill"></i>
                                        </li>
                                        <li>
                                          <i className="bi bi-star-fill"></i>
                                        </li>
                                        <li>
                                          <i className="bi bi-star-fill"></i>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Slider>
                      </div>
                    </Zoom>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CabinDetails;
