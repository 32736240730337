import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { fetchFaq } from "../data/faqSlice";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
const Faqs = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(fetchFaq());
  }, [dispatch]);

  const [openFaqs, setOpenFaqs] = useState(0);

  const toggleFaq = (index) => {
    setOpenFaqs((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Helmet>
                <meta
                  name="description"
                  content={data?.pageMetatagDescription}
                />
                <meta name="keywords" content={data?.pageMetatagTitle} />

                <title>{data?.pageTitle}</title>
              </Helmet>
              {/*banner */}

              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? data?.pageBannerMobile
                        : isTablet
                        ? data?.pageBannerTablet
                        : data?.pageBanner
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            <div className="breadcum-title">
                              <h4>{data?.pageTitle}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li> {data?.pageTitle}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*booking form */}

              <BookingFormGroup />

              {/*Faq */}
              <section id="faqs">
                <div className="faqs-section">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="section-title center inner mb-5">
                          <div className="section-thumb">
                            <img src="section-shape1.png" alt="" />
                          </div>
                          <h1 className="py-3">{data?.pageTitle} </h1>
                          <p>{data?.pageText}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {data?.list_FAQ.map((faq, index) => (
                        <Link
                          to="#."
                          key={index}
                          className="faq-item col-12 col-lg-6"
                          onClick={() => toggleFaq(index + 1)}
                        >
                          <Link
                            to="#."
                            className={`question  ${
                              openFaqs[index + 1] ? "active" : ""
                            }`}
                          >
                            {faq.faQ_Question}
                          </Link>
                          {openFaqs[index + 1] && (
                            <div
                              style={{
                                maxHeight: "fit-content",
                                minHeight: "fit-content",
                              }}
                              className="other-activities answer"
                              dangerouslySetInnerHTML={{
                                __html: `${faq.faQ_Answer}`,
                              }}
                            ></div>
                          )}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Faqs;
