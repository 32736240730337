import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BookingForm } from "../../components/BookingForm";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import { fetchDining } from "../../data/diningSlice.js";
import { useDispatch, useSelector } from "react-redux";
import "./dining.css";
import Loader from "./../../Loader";
import { useMediaQuery } from "react-responsive";
const Dining = () => {
  let hotelUrl = "";
  if (window.location.href.includes("/agatha-christie-dahabiya")) {
    hotelUrl = "agatha-christie-dahabiya";
  } else if (window.location.href.includes("/nile-capital-cruise")) {
    hotelUrl = "nile-capital-cruise";
  }
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.dining);

  useEffect(() => {
    dispatch(fetchDining(hotelUrl));
  }, [dispatch, hotelUrl]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1000 });
  return (
    <>
      {error ? (
        error.message
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              {" "}
              <Helmet>
                <meta
                  name="description"
                  content={data.pageMetatagDescription}
                />
                <meta name="keywords" content={data.pageMetatagTitle} />

                <title>{data.pageTitle}</title>
              </Helmet>
              {/*banner */}
              <section>
                <div
                  className="breadcumb-area d-flex align-items-center"
                  style={{
                    background: `url(${
                      isMobile
                        ? data?.pageBannerMobile
                        : isTablet
                        ? data?.pageBannerTablet
                        : data?.pageBanner
                    })`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    padding: "260px 0 170px",
                    textAlign: "center",
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="breacumb-content">
                          <Zoom duration="2500">
                            {" "}
                            <div className="breadcum-title">
                              <h4>{data.pageTitle}</h4>
                            </div>
                          </Zoom>
                          <Fade duration="2500" direction="up">
                            {" "}
                            <ul>
                              <li>
                                <Link to="/" style={{ color: "#fff" }}>
                                  Home
                                </Link>
                              </li>
                              <li className="px-2"> / </li>
                              <li> {data.pageTitle}</li>
                            </ul>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*booking form */}
              <BookingForm />
              {/*Dining list */}
              <section>
                <div className="facilities-area">
                  <div className="container">
                    <Zoom duration="2500" delay="0">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <div className="section-title center inner">
                            <div className="section-thumb">
                              <img src="section-shape1.png" alt="" />
                            </div>
                            <h1>{data.pageTitle}</h1>
                            <p className="section-desc-1">{data.pageText}</p>
                          </div>
                        </div>
                      </div>
                    </Zoom>
                    {data.list_Restaurants?.map((dining, index) => (
                      <div className="row add-boder">
                        <div className="col-12 col-md-12 col-lg-6">
                          <div
                            className="single-facilities-images-box"
                            data-cue="zoomIn"
                          >
                            <div className="facilities-thumb">
                              <img src={dining.restaurantPhoto} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 mt-3 mt-lg-0">
                          <div
                            className="single-facilities-box"
                            data-cue="zoomIn"
                          >
                            <div className="facilities-content">
                              <h4>Dining </h4>
                              <h1>{dining.restaurantName} </h1>
                              <p>{dining.restaurantSummery}</p>
                              {dining.isHaveDetails ? (
                                <Link
                                  className="facilities-button"
                                  to={`/${hotelUrl}/dining/${dining.restaurantURL}`}
                                >
                                  <i className="bi bi-arrow-right"></i>
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="facilities-number">
                              <h1>{index + 1} </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Dining;
